import { useState, useEffect } from 'react'
import './month_picker.css'

const months = getMonths();

function getMonths() {
    let months = [];
    for (let i = 0; i < 12; i++) {
        months.push(new Date(null, i + 1, null).toLocaleDateString("en", {month: "long"}))
    }

    return months;
}

export default function MonthPicker(props) {
    const [selectedYear, setSelectedYear] = useState();
    const [selectedMonth, setSelectedMonth] = useState();

    useEffect(() => {
        console.log("Initializing", props.initialYear, props.initialMonth)
        setSelectedYear(props.initialYear);
        setSelectedMonth(props.initialMonth);
    }, [])

    useEffect(() => {
        if (selectedYear && selectedMonth) {
          console.log("onChange", selectedYear, selectedMonth)
          props.onChange({year: selectedYear, month: selectedMonth + 1});
        }
    }, [props, selectedYear, selectedMonth])
    
    const adjustYear = (increment) => {
        return () => {
            console.log("Increment year by", increment)
            setSelectedYear(selectedYear + increment);
        }
    }

    const adjustMonth = (increment) => {
        return () => {
            console.log("Adjusting month by", increment)
            if (selectedMonth + increment < 0) {
                setSelectedYear(selectedYear - 1);
                setSelectedMonth(12 + increment);
            } else if (selectedMonth + increment > 11) {
                setSelectedYear(selectedYear + 1);
                setSelectedMonth(increment - 1);
            } else {
                setSelectedMonth(selectedMonth + increment);
            }
        }
    }

    console.log("Rendering", selectedYear, selectedMonth)

    return [
      <div className="MonthPicker">
        <div className="mp-container">
          <span className="mp-button" onClick={adjustYear(-1)}>{"<<"}</span>
          <span className="mp-button" onClick={adjustMonth(-1)}>{"<"}</span>
          <div className="mp-date-container">
            <div className="mp-year">{selectedYear}</div>
            <div className="mp-month">{months[selectedMonth]}</div>
          </div>
          <span className="mp-button" onClick={adjustMonth(1)}>{">"}</span>
          <span className="mp-button" onClick={adjustYear(1)}>{">>"}</span>
        </div>
      </div>
    ];
}