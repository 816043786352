import { useState, useEffect } from "react";
import moment from "moment";

import ChargerPicker from "./charger_picker";
import ConsumptionChart from "./consumption-chart/consumption_chart";

export default function Consumption(props) {
  const [consumption, setConsumption] = useState();
  const [prices, setPrices] = useState();
  const [charger, setCharger] = useState();
  const [chargers, setChargers] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [loadingChargers, setLoadingChargers] = useState(true);

  const getChargers = () => {
    fetch("/api/chargers").then((res) => {
      res.json().then((data) => {
        setChargers(data);
        setLoadingChargers(false)
      });
    });
  };

  useEffect(() => {
    setLoadingChargers(true);
    getChargers();
  }, [props]);

  useEffect(() => {
    if (consumption && prices) {
      setLoadingData(false);
    } else {
      setLoadingData(true);
    }
  }, [consumption, prices]);

  useEffect(() => {
    if (!charger) {
        return
    }
    const toTime = moment();
    const to = toTime.format("YYYY-MM-DDTHH");
    const fromTime = toTime.subtract(7, "days");
    const from = fromTime.format("YYYY-MM-DDTHH");
    fetch(
      "/api/consumption/" + charger.id + "?to=" + to + "&from=" + from
    ).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
            const toConsumption = data.map((item) => ({x: item.date, y: item.consumption}));
            setConsumption(toConsumption);
        });
      } else {
        props.logout();
      }
    });

    fetch("/api/spotprice?to=" + to + "&from=" + from).then(
        (res) => {
          res.json().then((data) => {
            const toPrices = data.map((item) => ({x: item.date, y: item.price}));
            setPrices(toPrices);
          });
        }
      );
  }, [charger]);

  if (loadingChargers) {
    return "Loading chargers..."
  }

  const render_plot = () => {
    if (!charger) {
      return "";
    }

    if (loadingData) {
        return "Loading..."
    }

    return (
        <ConsumptionChart consumption={consumption} prices={prices} />
    );
  };

  return (
    <div style={{padding: "20px"}}>
      <h3>Consumption for all the chargers</h3>
      Please select a charger from the dropdown.
      <ChargerPicker
        chargers={chargers}
        selectedCharger={charger}
        selectCharger={setCharger}
      />
      {render_plot()}
    </div>
  );
}
