import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar/navbar';
import Consumption from './components/consumption';
import Login from './components/login/login';
import Home from './components/home';
import Report from './components/report/report'

function App() {
  const [profile, setProfile] = useState()
  const [loading, setLoading] = useState(true)

  const getProfile = () => {
    fetch('/api/user/profile').then((response) => {
      if (response.ok) {
        response.json().then((profile) => setProfile(profile));
      } else {
        if (response.status !== 401) {
          throw new Error(`Couldn't fetch user! Status: ${response.status}`);
        }
      }
      setLoading(false)
    });
  }

  const logout = () => {
    fetch('/api/logout', {
      method: 'POST'
    }).then(() => setProfile(null));
  }

  useEffect(() => {
    fetch('/api/user/poll').then(getProfile());
  }, []);

  const handleAuthenticated = () => {
    setLoading(true)
    getProfile()
  }

  if (loading) {
    return "Loading..."
  }

  if (!profile) {
    return <Login onAuthenticated={handleAuthenticated}/>
  }

  return (
    <div>
      <BrowserRouter>
        <Navbar logout={logout}/>
        <Routes>
          <Route index element={<Home charger={profile} logout={logout} />} />
          <Route path="/admin/consumptions" element={<Consumption logout={logout} />} />
          <Route path="/admin/report" element={<Report />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;