import {
  AnimatedAxis, // any of these can be non-animated equivalents
  AnimatedGrid,
  AnimatedLineSeries,
  XYChart,
  Tooltip,
  
} from "@visx/xychart";

import { curveStepAfter } from '@visx/curve';

import "./consumption_chart.css"

export default function ConsumptionChart(props) {
  const accessors = {
    xAccessor: (d) => new Date(d.x),
    yAccessor: (d) => d.y,
  };

  const getUnit = (key) => {
    if (key === 'Consumption') {
        return 'kWh'
    } else if (key === 'Price') {
        return 'kr/kWh'
    }
  }

  return (
    <div className="consumption-container">
      <XYChart
        height={300}
        xScale={{ type: "time" }}
        yScale={{ type: "linear" }}
      >
        <AnimatedAxis orientation="bottom" />
        <AnimatedAxis orientation="left" />
        {/* <AnimatedGrid columns={false} numTicks={4} /> */}
        <AnimatedLineSeries
          dataKey="Consumption"
          curve={curveStepAfter}
          data={props.consumption}
          {...accessors}
        />

        <AnimatedLineSeries
          dataKey="Price"
          curve={curveStepAfter}
          data={props.prices}
          {...accessors}
        />
        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          showSeriesGlyphs
          renderTooltip={({ tooltipData, colorScale }) => (
            <div>
              {new Date(tooltipData.nearestDatum.datum.x).toLocaleString(
                "sv-SE"
              )}
              {Object.entries(tooltipData.datumByKey).map(([name, point]) => {
                return (
                  <div key={point.key} style={{ color: colorScale(point.key) }}>
                    {point.key} {accessors.yAccessor(point.datum).toFixed(2)}
                    {getUnit(point.key)}
                  </div>
                );
              })}
              Total hourly cost{" "}
              {(
                tooltipData.datumByKey.Consumption.datum.y *
                tooltipData.datumByKey.Price.datum.y
              ).toFixed(2)}
              kr
            </div>
          )}
        />
      </XYChart>
    </div>
  );
}
  