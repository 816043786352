import { useState, useEffect } from 'react';
import {Form, Button} from 'react-bootstrap'

import './login.css'

export default function Login(props) {
  const [userName, setUserName] = useState()
  const [password, setPassword] = useState()
  const [error, setError] = useState('')

  const handleUserNameChange = (event) => {
    setUserName(event.target.value);
  }

  const handlePaswordChange = (event) => {
    setPassword(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch("/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_name: userName, password: password }),
    }).then((response) => {
      if (response.ok) {
        props.onAuthenticated();
      } else {
        if (response !== 401) {
          setError(
            `Couldn't log in Status: ${response.status} Reason: ${response.body}`
          );
        }

        setError("Wrong username and/or password");
      }
    });
  };

  return (
    <div className="login-wrapper">
      <div className="container">
        <div className="row">
          <p className="login-title">TIMESPOTTER</p>
        </div>
        <div className="row">
          <div className="login-container">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Text>
                  Login with your {' '}
                  <a href="https://www.easee.com">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 602.8 101.7"
                    xmlSpace="preserve"
                    height="10px"
                  >
                    <title>Easee Logo</title>
                    <g>
                      <g>
                        <path
                          d="M208.4,43.9l0.3,3.2c0.1,1.3,0.2,2.6,0.2,3.7c0,21.8-17.7,39.5-39.5,39.5c-21.8,0-39.5-17.7-39.5-39.5
            c0-1.2,0.1-2.4,0.2-3.7l0.3-3.2l-11,16l0.1,0.3c4.5,24,25.5,41.4,49.9,41.4c15.3,0,29.9-7,39.5-18.9v14.1h11.3V61.2L208.4,43.9z"
                        ></path>
                        <path
                          d="M283.2,49.5l0.5,0.2c0.8,0.3,1.5,0.6,2.2,0.8c0.7,0.3,1.5,0.6,2.3,0.9l0.5,0.2c8.8,3.2,26.1,9.1,28,18.6l8.2-9.5
            c-6.6-10.6-22.3-16-32.4-19.7l-0.4-0.1c-0.8-0.3-1.6-0.6-2.4-0.9c-0.9-0.3-1.9-0.7-2.8-1c-8.6-3.1-20.3-7.4-20.3-13.4
            c0-7.6,9.7-14.1,21.1-14.1c11.5,0,21.1,6.5,21.1,14.1v0.9h11.3v-0.9c0-14-14.5-25.4-32.4-25.4c-17.9,0-32.4,11.4-32.4,25.4
            C255.5,39.5,271.5,45.3,283.2,49.5z"
                        ></path>
                        <path
                          d="M480.7,50.4h95.1c0-13.5-5.8-26.6-15.3-35.9C550.9,5.2,538.3,0.1,525,0.1c-24.5,0-45.7,17.3-50,42L480.7,50.4z
             M487.2,39.5c5-16.6,20.4-28.2,37.8-28.2c17.4,0,32.8,11.5,37.8,28.2H487.2z"
                        ></path>
                        <path
                          d="M450.4,51.1h-87.9l-6,8.8l0,0c3.9,23.5,25.5,41.8,50,41.8c13.9,0,27.4-5.8,37-16l0.6-0.6l-8.2-7.8l-0.6,0.6
            c-7.5,8-17.8,12.4-28.7,12.4c-17.4,0-32.7-11.5-37.8-28h89.4L450.4,51.1z"
                        ></path>
                        <path
                          d="M560.5,68.3c-7.1,14.1-20.7,21.9-35.3,22c-12.4,0.1-23.6-5.5-32.6-13.8c-8.6-8-14.9-18-21.5-27.6
            c-6.3-9.2-12.2-18.6-19.7-26.9c-7.8-8.6-17.1-15.8-28.2-19.4c-12.2-4-25.8-3.4-37.6,1.6C364,13.2,351.5,34.5,339,53
            c-6.3,9.3-12.8,18.7-21.5,26c-9.5,8-21.3,12.4-33.8,11.2c-25.6-2.4-39.8-26.6-52.8-45.8c-12.3-18.3-26.3-37.5-49-42.9
            c-12.5-3-25.8-1.4-37.2,4.4c-10.1,5.2-18.4,13.3-25.4,22.1c-7,8.9-13,18.5-19.4,27.9c-6.4,9.4-13.4,18.8-22.6,25.5
            c-10.1,7.3-22.8,10.7-35.2,8c-11.7-2.6-21.8-10.6-27-21.5c-2.6-5.5-3.9-11.5-3.9-17.5c6.7,0,78.4,0,83.8,0l5.8-8.4
            c-0.2-1.5-0.9-4.1-1.3-5.4c-0.7-2.5-1.7-5-2.8-7.4c-2.2-4.6-5.1-8.8-8.5-12.5C73.6,0.2,49.9-4.3,30,4.5C11.6,12.7,0.1,31,0,50.9
            c-0.1,18,10,35.1,25.5,44.1c15.8,9.1,35.7,8.8,51.6,0.1c14.3-7.8,24.3-21,33.2-34.3c5-7.4,9.9-15,15.2-22.2
            c5.1-6.9,10.9-13.5,17.9-18.6c7.5-5.4,16.4-8.6,25.6-8.6c9.3,0,18.2,3,25.8,8.4c7.1,5,12.9,11.6,18,18.5
            c5.5,7.3,10.4,15.1,15.5,22.7c9,13.3,19.1,26.6,33.5,34.3c16.6,8.9,37.1,8.6,53.4-0.8c14-8.1,23.8-21.3,32.7-34.5
            c5.1-7.5,10-15.2,15.4-22.4c5.2-6.9,11.1-13.5,18.4-18.4c7.7-5.2,16.6-8,25.9-7.7c9.2,0.3,17.8,3.6,25.2,9
            c7,5.2,12.7,11.9,17.9,18.9c5.2,7,9.9,14.4,14.8,21.6c9,13.3,19.1,26.6,33.5,34.3c17.2,9.2,38.7,8.6,54.8-2.5
            c7.3-5.1,13-11.9,17-19.9C568.9,71.9,561.6,68.8,560.5,68.3z M19.2,27.2c6.7-8.9,17-14.8,28.2-15.7c11.7-1,23.4,3.3,31.6,11.7
            c4.5,4.6,7.8,10.2,9.7,16.3c-8.5,0-72.5,0-75.6,0C14.3,35.1,16.4,30.9,19.2,27.2z"
                        ></path>
                        <g>
                          <path
                            d="M590.3,25c-6.9,0-12.5-5.6-12.5-12.5S583.4,0,590.3,0c6.9,0,12.5,5.6,12.5,12.5S597.2,25,590.3,25z M590.3,1.9
                c-5.9,0-10.6,4.8-10.6,10.6s4.8,10.6,10.6,10.6c5.9,0,10.6-4.8,10.6-10.6S596.2,1.9,590.3,1.9z"
                          ></path>
                        </g>
                        <g>
                          <path
                            d="M588.3,13.9v5.3h-2.2V5.9h3.7c1.7,0,3,0.3,3.8,1s1.2,1.6,1.2,2.9c0,1.6-0.9,2.8-2.6,3.5l3.7,5.9h-2.5l-3.2-5.3H588.3z
                 M588.3,12.1h1.5c1,0,1.7-0.2,2.2-0.6c0.5-0.4,0.7-0.9,0.7-1.7c0-0.7-0.2-1.3-0.7-1.6c-0.5-0.3-1.2-0.5-2.2-0.5h-1.4V12.1z"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  </a>
                  {' '} account.
                </Form.Text>
                <Form.Control
                  type="email"
                  placeholder="E-mail"
                  onChange={handleUserNameChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={handlePaswordChange}
                />
                <Form.Text style={{color: 'ligth-red'}}>
                  {error}
                </Form.Text>
              </Form.Group>
              <Button variant="primary" type="submit">
                Login
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}