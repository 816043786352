import { useEffect } from "react";
import ConsumptionChart from "./consumption-chart/consumption_chart";
import moment from "moment";
import { useState } from "react";

const data1 = [
  { x: "2020-01-01", y: 50 },
  { x: "2020-01-02", y: 10 },
  { x: "2020-01-03", y: 20 },
];

export default function Home(props) {

  const [consumption, setConsumption] = useState();
  const [prices, setPrices] = useState();
  const [charger, setCharger] = useState();

  useEffect(() => {
    fetch("/api/user/products").then((res) => {
        if (res.ok) {
            res.json().then((data) => setCharger(data.id))
        } else {
            console.log('something went wrong', res)
        }
    });
    console.log('dispatched request')
  }, [])
  useEffect(() => {
    if (!charger) {
        return
    }
    const toTime = moment();
    const to = toTime.format("YYYY-MM-DDTHH");
    const fromTime = toTime.subtract(7, "days");
    const from = fromTime.format("YYYY-MM-DDTHH");
    fetch(
      "/api/consumption/" + charger + "?to=" + to + "&from=" + from
    ).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
            const toConsumption = data.map((item) => ({x: item.date, y: item.consumption}));
            setConsumption(toConsumption);
        });
      } else {
        props.logout();
      }
    });

    fetch("/api/spotprice?to=" + to + "&from=" + from).then(
        (res) => {
          res.json().then((data) => {
            const toPrices = data.map((item) => ({x: item.date, y: item.price}));
            setPrices(toPrices);
          });
        }
      );
  }, [charger]);

  const renderChart = () => {
    if (consumption && prices) {

        return <ConsumptionChart consumption={consumption} prices={prices} />
    }

    return ''
  }

  return (
    <div style={{padding: "20px"}}>
        <h3>Good day!</h3>
        Here is a brief summary of your consumption so far. Remember to charge your car when the hourly prices are low!
        {renderChart()}
    </div>
  );
}
