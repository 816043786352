import { LinkContainer } from 'react-router-bootstrap'
import { NavDropdown, Nav, Navbar, Container } from 'react-bootstrap';

import './navbar.css'

export default function TimespotNavbar(props) {
  return (
    <Navbar bg="light" expand="sm">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              alt=""
              src="../../favicon-32x32.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
            <span className="navbar-brand">Timespot</span>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {/* <Nav>
            <LinkContainer to="/consumption">
              <NavDropdown.Item>Consumption</NavDropdown.Item>
            </LinkContainer>
          </Nav> */}
          <Nav className="ms-auto">
            <NavDropdown title="Admin" id="basic-nav-dropdown">
              <LinkContainer to="/admin/consumptions">
                <NavDropdown.Item>Consumptions</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/admin/report">
                <NavDropdown.Item>Reports</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <Nav.Link onClick={props.logout}>Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}