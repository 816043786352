import {useState} from 'react'
import MonthPicker from "../month-picker/month_picker";
import "./report.css"

export default function Report() {
    const currentDate = new Date();
    const [month, setMonth] = useState(currentDate.getMonth());
    const [year, setYear] = useState(currentDate.getFullYear());

    const handleMonthChange = (event) => {
        console.log(event)
        const {year: yearIn, month: monthIn} = event;
        setYear(yearIn)
        setMonth(monthIn)
        console.log(yearIn, monthIn)
        console.log(new Date(yearIn, monthIn).toISOString().slice(0,7))
    }

    const getDateString = () => {
        const dateString = new Date(year, month).toISOString().slice(0,7);
        console.log("getDateString", dateString)
        return dateString;
    }

    return (
      <div style={{ float: "left", width: "100%", padding: "20px" }}>
        <div className="report-container">
          <h1>Generate report</h1>
          <p>
            Generate reports for user consumption. The report will be a
            consumption summary of all the users in the system.
          </p>
          <MonthPicker
            onChange={handleMonthChange}
            initialYear={year}
            initialMonth={month}
          />
          <a href={"/api/report?month=" + getDateString()}>
            <div className="report-download-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                class="bi bi-cloud-download"
                viewBox="0 0 16 16"
              >
                <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
              </svg>
            </div>
          </a>
        </div>
      </div>
    );
}