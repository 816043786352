import Dropdown from 'react-bootstrap/Dropdown';

export default function ChargerPicker(props) {
    return (
      <Dropdown>
        <Dropdown.Toggle>
          {props.selectedCharger?.name || "Select a charger"}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {props.chargers.map((charger) => {
            return (
              <Dropdown.Item key={charger.id} onClick={() => props.selectCharger(charger)}>
                {charger.name}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    )
  }